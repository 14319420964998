<template>
  <div class="page-wrapper">
    
    <header class="main-header always-sticky">
      <div class="left">
        <img src="@/assets/img/logo.svg" class="logo" alt="Logo Tremblant">
        <img src="@/assets/img/tagline-fr.svg" v-if="$route.meta.lang === 'fr'" class="tagline" alt="Tagline Tremblant">
        <img src="@/assets/img/tagline-en.svg" v-else-if="$route.meta.lang === 'en'" class="tagline" alt="Tagline Tremblant">
      </div>
      <div class="right">
        <div class="links">
          <a href="tel:18887381777">1-888-738-1777</a>
          <p>|</p>
          <router-link v-if="pageData && $route && $route.meta.lang === 'fr'" :to="{ name: 'pageEn', params: { pageData } }" class="lang">EN</router-link>
          <router-link v-else-if="pageData && $route && $route.meta.lang === 'en'" :to="{ name: 'page', params: { pageData } }" class="lang">FR</router-link>
        </div>
        <a :href="$t('site.bh-ski-link')" target="_blank" class="cta red">{{ $t('site.achetez-long') }}</a>
      </div>
      <a href="tel:18887381777" class="phone-mobile"><img src="@/assets/img/phone.svg" alt="Call Tremblant"></a>
      <div class="mobile-cta">
        <a :href="$t('site.bh-ski-link')" target="_blank" class="cta red">{{ $t('site.achetez-long') }}</a>
      </div>
    </header>

    <section class="terms-wrap">
      <div v-if="$route.meta.lang === 'fr'">

        <button @click.prevent="goback" class="cta prune">Retour</button><br><br>

        <h1 class="big-title">Modalités<br>Billets de ski 2022/23</h1>

        <h2 class="small-title">Validité</h2>
        <ul>
          <li class="regular-text">Les billets réservés d’avance sont date spécifique et valables à compter de la première journée d’utilisation prévue. 1 journée de pause est permis pour les billets 3 jours, 4 jours, 5 jours, 6 jours ou 7 jours. Pas de pause permis pour les billets 2 jours.</li>
          <li class="regular-text">Tarification propre du lundi au vendredi, le samedi et dimanche et à la haute saison du 27 décembre 2022 au 5 janvier 2023 et du 18 février au 23 février 2023.</li>
        </ul>
        
        <h2 class="small-title">Achat</h2>
        <ul>
          <li class="regular-text">Doit être acheté au moins 48 heures d’avance, en ligne ou par téléphone au 1-888-738-1777.</li>
          <li class="regular-text">Les billets bambin (0-2 ans) sont en vente uniquement sur place ou par téléphone au 1-888-738-1777.</li>
        </ul>

        <h2 class="small-title">Livraison</h2>
        <ul>
          <li class="regular-text">Vous devez récupérer votre billet à l’un de nos comptoirs multi-service (carte d'identité avec photo obligatoire) ou aux bornes d’impression la veille ou le jour même de votre arrivée.</li>
          <li class="regular-text">Vous recevrez un courriel contenant votre code QR 24h avant la première journée d’utilisation prévue. Veuillez vous présenter à l’une de nos bornes d’impression pour imprimer vos billets.</li>
          <li class="regular-text">Si vous avez réservé ce produit avec votre hébergement sur le site, le billet vous sera livré directement à votre hôtel.</li>
        </ul>

        <h2 class="small-title">Remboursement</h2>
        <p class="regular-text">Remboursable jusqu’à 72 heures avant la première journée d’utilisation prévue. Non remboursable après ce délai.</p>

        <h2 class="small-title">Restrictions</h2>
        <p class="regular-text">Produit et rabais incessibles (ne peuvent être transférés d'une personne à une autre).</p>

        <div class="table-wrap new">
          <div class="table">
            <div class="row">
              <div class="cell one"></div>
              <div class="cell full"><p class="regular-text">19 octobre au 29 novembre 2022</p></div>
            </div>
            <div class="row">
              <div class="cell one"></div>
              <div class="cell three light"><p class="regular-text">Lundi au vendredi</p></div>
              <div class="cell three light"><p class="regular-text">Samedi et dimanche</p></div>
              <div class="cell three light"><p class="regular-text short-lh">Haute saison</p></div>
            </div>
            <div class="row">
              <div class="cell one darker"><p class="regular-text">Adulte (18-69)</p></div>
              <div class="cell three soft"><p class="regular-text">89 $</p></div>
              <div class="cell three soft"><p class="regular-text">102 $</p></div>
              <div class="cell three soft"><p class="regular-text">145 $</p></div>
            </div>
            <div class="row">
              <div class="cell one darker"><p class="regular-text">Ainé (70+)</p></div>
              <div class="cell three soft"><p class="regular-text">77 $</p></div>
              <div class="cell three soft"><p class="regular-text">88 $</p></div>
              <div class="cell three soft"><p class="regular-text">127 $</p></div>
            </div>
            <div class="row">
              <div class="cell one darker"><p class="regular-text">Jeune (13-17)</p></div>
              <div class="cell three soft"><p class="regular-text">67 $</p></div>
              <div class="cell three soft"><p class="regular-text">76 $</p></div>
              <div class="cell three soft"><p class="regular-text">105 $</p></div>
            </div>
            <div class="row">
              <div class="cell one darker"><p class="regular-text">Enfant (5-12)</p></div>
              <div class="cell three soft"><p class="regular-text">51 $</p></div>
              <div class="cell three soft"><p class="regular-text">58 $</p></div>
              <div class="cell three soft"><p class="regular-text">82 $</p></div>
            </div>
            <div class="row">
              <div class="cell one darker"><p class="regular-text">Peewee (3-4)</p></div>
              <div class="cell all soft"><p class="regular-text">2 jours : 30$ - 3 à 4 jours : 37.50$</p></div>
            </div>
            <div class="row">
              <div class="cell one darker"><p class="regular-text">Bambin (0-2)</p></div>
              <div class="cell all soft"><p class="regular-text">Gratuit</p></div>
            </div>
          </div>
        </div>

      </div>

      <div v-else-if="$route.meta.lang === 'en'">

        <button @click.prevent="goback" class="cta prune">Back</button><br><br>

        <h1 class="big-title">2022/23 Winter Lift Tickets<br>Terms & Conditions</h1>

        <h2 class="small-title">Validity</h2>
        <ul>
          <li class="regular-text">Multi-day tickets are date specific and valid starting from the first planned day of use. 1 day of break is permitted for 3-day tickets, 4-day tickets, 5-day tickets, 6-day tickets or 7-day tickets. No break is permitted for 2-day tickets.</li>
          <li class="regular-text">Rates specific Monday to Friday, Saturday and Sunday and hi-season from December 27, 2022, to January 5, 2023, and February 18 to 23, 2023.</li>
        </ul>

        <h2 class="small-title">Purchase and Booking</h2>
        <ul>
          <li class="regular-text">Must be purchased at least 48 hours in advance, online or by phone at 1-888-738-1777.</li>
          <li class="regular-text">Toddler tickets must be purchased at the ticket offices or by phone at 1-888-738-1777.</li>
        </ul>

        <h2 class="small-title">Delivery</h2>
        <ul>
          <li class="regular-text">You must pick-up your ticket at one of our multi-service counters (photo ID required) or ticket printing kiosks the before or day of your arrival.</li>
          <li class="regular-text">A QR Code will be sent to you by email 24h prior to the first planned day of use. You can pick-up your tickets at one of our ticket printing kiosks.</li>
          <li class="regular-text">If you purchased your Multi-day Ticket with onsite lodging, it will be delivered to your hotel.</li>
        </ul>

        <h2 class="small-title">Refund</h2>
        <p class="regular-text">Can be refunded up to 72 hours prior to first planned day of use. Not refundable past this period.</p>

        <h2 class="small-title">Restrictions</h2>
        <p class="regular-text">Non-transferable (product and discounts cannot be transferred from one person to the next).</p>

        <div class="table-wrap new">
          <div class="table">
            <div class="row">
              <div class="cell one"></div>
              <div class="cell full"><p class="regular-text">October 19 to November 29 2022</p></div>
            </div>
            <div class="row">
              <div class="cell one"></div>
              <div class="cell three light"><p class="regular-text">Monday to Friday</p></div>
              <div class="cell three light"><p class="regular-text">Saturday / Sunday</p></div>
              <div class="cell three light"><p class="regular-text short-lh">High season</p></div>
            </div>
            <div class="row">
              <div class="cell one darker"><p class="regular-text">Adult (18-69)</p></div>
              <div class="cell three soft"><p class="regular-text">$89</p></div>
              <div class="cell three soft"><p class="regular-text">$102</p></div>
              <div class="cell three soft"><p class="regular-text">$145</p></div>
            </div>
            <div class="row">
              <div class="cell one darker"><p class="regular-text">Senior (70+)</p></div>
              <div class="cell three soft"><p class="regular-text">$77</p></div>
              <div class="cell three soft"><p class="regular-text">$88</p></div>
              <div class="cell three soft"><p class="regular-text">$127</p></div>
            </div>
            <div class="row">
              <div class="cell one darker"><p class="regular-text">Youth (13-17)</p></div>
              <div class="cell three soft"><p class="regular-text">$67</p></div>
              <div class="cell three soft"><p class="regular-text">$76</p></div>
              <div class="cell three soft"><p class="regular-text">$105</p></div>
            </div>
            <div class="row">
              <div class="cell one darker"><p class="regular-text">Child (5-12)</p></div>
              <div class="cell three soft"><p class="regular-text">$51</p></div>
              <div class="cell three soft"><p class="regular-text">$58</p></div>
              <div class="cell three soft"><p class="regular-text">$82</p></div>
            </div>
            <div class="row">
              <div class="cell one darker"><p class="regular-text">Peewee (3-4)</p></div>
              <div class="cell all soft"><p class="regular-text">2 days : $30 - 3 to 4 days : $37.50</p></div>
            </div>
            <div class="row">
              <div class="cell one darker"><p class="regular-text">Toddler (0-2)</p></div>
              <div class="cell all soft"><p class="regular-text">Free</p></div>
            </div>
          </div>
        </div>

     </div>
      
    </section>

    <footer-bar></footer-bar>

    <!--Logo Partenaire-->
    <div class="logo-partenaire">
      <a v-if="$route.meta.lang === 'fr'" href="https://www.laurentides.com/fr" target="_blank"
        ><img src="@/assets/img/logo-tl-fr.png" alt="Logo Tourisme Laurentides"
      /></a>
      <a v-else-if="$route.meta.lang === 'en'" href="https://www.laurentides.com/en" target="_blank"
        ><img src="@/assets/img/logo-tl-en.png" alt="Logo Tourisme Laurentides"
      /></a>
    </div>

    <div class="overlay-form-loding" :class="{ show: formlodging }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidelodgingform">
      <div class="form-wrap">
        <h3 class="medium-title">{{ $t('site.reservez-long') }}</h3>

        <form
          action="https://reservations.tremblant.ca/Ecomm/Shop/Lodging/2624850/fr-CA/?_ga=2.247307652.1090954148.1629141557-1179124804.1583853558&_gl=1*t5gc5p*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTIyOTEzOC4xNC4wLjE2MjkyMjkxMzguMA"
          class="lodging-form"
          target="_blank"
        >
          <div class="input">
            <p class="regular-text">{{ $t('site.arrivee') }}</p>
            <date-picker
              name="arrivaldate"
              v-model="time1"
              :lang="this.datepickerLang"
              :disabledDate="disabledBeforeToday"
              :inputAttr="{name: 'arrivaldate', required: 'required'}"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.depart') }}</p>
            <date-picker
              name="departuredate"
              v-model="time2"
              :lang="this.datepickerLang"
              :disabledDate="disabledBeforeToday"
              :inputAttr="{name: 'departuredate', required: 'required'}"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.adulte') }}</p>
            <input
              name="adultcount"
              type="number"
              value="2"
              min="1"
              required
            >
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.enfant') }}</p>
            <input
              name="childCount"
              type="number"
              value="0"
              required
            >
          </div>
          <input type="submit" class="cta submit blanc-rouge" :value="$t('site.reservez-long')">
        </form>
      </div>
      <div class="opacity" @click.prevent="hidelodgingform"></div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'

import * as LottiePlayer from '@lottiefiles/lottie-player'

import GLightbox from 'glightbox'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import trans from '@/translations/index'

import NewsLetter from '@/components/NewsLetter'
import FooterBar from '@/components/FooterBar'

export default {
  components: { NewsLetter, FooterBar, DatePicker },
  head() {
    return {
      title: {
        inner: "Éclaté de haut en bas"
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: `Tremblant - Offres` },
        {
          name: 'description',
          content: `Profitez des meilleures offres et prix de l'année.`,
          id: 'desc'
        },
        // Twitter OG
        { name: 'twitter:title', content: `Tremblant - Offres` },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: `Profitez des meilleures offres et prix de l'année.`
        },
        // Facebook / Open Graph
        { property: 'og:title', content: `Tremblant - Offres` },
        { property: 'og:site_name', content: `Tremblant - Offres` },
        {
          property: 'og:description',
          content: `Profitez des meilleures offres et prix de l'année.`
        },
        // Og Image
        {
          p: 'og:image',
          c:
            ''
        },
        {
          name: 'twitter:image',
          content: ''
        }
      ]
    }
  },
  props: {
    pageData: {
      type: Object,
      required: true,
      default: null
    },
  },
  data() {
    return {
      toggle: true,
      formlodging: false,
      sidepanel: false,
      lottieInstance: null,
      lightboxelement: null,
      datepickerLang: null,
      time1: new Date(),
      time2: null
    }
  },
  computed: mapState('app', ['appTitle']),
  methods: {
    goback() {
        return this.$router.go(-1)
    },
    table1() {
        this.toggle = true
    },
    table2() {
        this.toggle = false
    },
    showlodgingform() {
        if(this.formlodging) {
          this.formlodging = false
        } else {
          this.formlodging = true
        }
    },
    hidelodgingform() {
      this.formlodging = false
    },
    showsidepanel() {
        if(this.sidepanel) {
          this.sidepanel = false
        } else {
          this.sidepanel = true
        }
    },
    hidesidepanel() {
      this.sidepanel = false
    },
    disabledBeforeToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    snowscroll() {
      const st = document.documentElement.scrollTop

      const element = this.$el.querySelector('.snow-background')
      const elementStyle = element.style

      element.style.top = `${ -150 + st /2}px`
    } 
  },
  mounted () {
    this.lottieInstance = LottiePlayer

    this.lightboxelement = GLightbox
    GLightbox()

    const dt = new Date()
    dt.setDate(dt.getDate() + 1)
    this.time2 = dt

    this.datepickerLang = trans.fr.datepicker
    if (this.$route.meta.lang === 'en') {
      this.datepickerLang = trans.en.datepicker
    }
  },
  created() {
      window.addEventListener('scroll', () => {
        this.snowscroll()
      })
    },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
